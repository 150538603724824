import { Container } from '@mui/material';
import React from 'react'
import DesktopMenu from './component/DesktopMenu';
import MobileMenu from './component/MobileMenu';

import twitter from "../../../assets/twitter.png";
import telegram from "../../../assets/telegram.png";
import { tg, x } from '../../../links';
import emotionStyled from '@emotion/styled';
const SOcialimg = emotionStyled.img`
width:77px;
height:85px;
 @media screen and (max-width:900px){
    display: none;
}
`
const Wrap = emotionStyled.div`
display:flex;
align-items:flex-start;
gap:10px;
margin:30px 0px 0;
@media screen and (max-width:1270px){
  margin:30px 10px 0;
  }
  @media screen and (max-width:1170px){
    gap:1px;
margin:10px 0px 0;
}
  @media screen and (max-width:1040px){
    display: flow;
}
`
const LockerMenu = (props) => {
    const menuList = [ // You can pass 4 perameters for menu title, link, target and customClass
        {
        title: "Home",
        link: "/",
        },
        {
        title: "About",
        link: "#About",
        },
        {
        title: "Merchandise",
        link: "#Merchandise",
        },
        {
        title: "Tokenomics",
        link: "#Tokenomics",
        },
        {
        title: "Roadmap",
        link: "#Roadmap",
        },
        {
        title: "Team",
        link: "#Team",
        },
        {
        title: "News",
        link: "#News",
        },
        // {
        // title: "Buy Now",
        // link: "",
        // customClass: "",
        // target: "_blank"
        // },
    ];
  return (
    <Wrap >
        <DesktopMenu menuList={menuList} />
      <div>
        <a href={tg} target="_blank">
        <SOcialimg src={telegram}  alt="telegram" />
        </a>
      </div>
        <div>
        <a href={x} target="_blank">
        <SOcialimg src={twitter}  alt="twitter" />
        </a>
      </div>
        <MobileMenu menuList={menuList} />
    </Wrap>
  )
}

export default LockerMenu