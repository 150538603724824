import React, { useState } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// Styles for Phase Cards

const CarouselWrapper = styled.div`
padding: 50px 0px;
position: relative;

// Vertical line for mobile
@media (max-width: 768px) {
  &::before {
      // content: '';
      // position: absolute;
      // left: 50%;
      // top: 0;
      // width: 3px;
      // height: 100%;
      // background-color: black;
      // z-index: -1;
    }
  }
    `;// Container for the entire button
    const ButtonContainer = styled.div`
      width: 200px;
      height: 60px;
      border-radius: 10px;
      background-color: #C8EE5B; /* Yellow background */
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      cursor: pointer;
      border: 2px solid black;
      padding: 5px; /* Add padding to create space for the gap */
    `;
    
    // Sliding part (green overlay)
    const Sliderbutton = styled.div`
      width: 90px; /* Reduced width to allow for the gap */
      height: 50px;
      background-color: #43a047; /* Green color */
      border-radius: 8px; /* Slightly smaller radius to fit inside the gap */
      position: absolute;
      top: 3px; /* Adjust top to fit inside the container */
      left: ${(props) => (props.isV1 ? '5px' : '100px')}; /* Adjust left position with gap */
      transition: left 0.4s ease; /* Smooth sliding transition */
      border: 2px solid black;
    `;
    
    // Text inside the button
    const ButtonText = styled.div`
      flex: 1;
      text-align: center;
      font-size: 30px;
      font-family: KB;
      font-weight: bold;
      z-index: 1;
      color: ${(props) => (props.isActive ? 'white' : '#8D8D8D')};
    `;
    
const PhaseCard = styled.div`
      position: relative;
      background-color:#6DCF77;
      padding: 0px;
      border-radius: 5px;
      text-align: center;
      font-size: 1.2rem;
      min-height: 200px;
      
    border:2px solid #000;
      margin: 10px;
      text-align:left;
    // width:430px;
      // Add the line connecting to the next card
    
    `;

const WrapModel = styled.div`
    background-color:#6DCF77;
    padding:0px 30px;
    border-radius:5px;
    border:2px solid #000;
    margin: 10px;
    // width:430px;
    color:black;
    position:relative;
   &::after {
     content: '';
    position: absolute;
    right: -83px;
    bottom: 50%;
    width: 82px;
    height: 2px;
    background-color: black;
    z-index: 0;
  }
 @media screen and (max-width: 900px) {
    // margin: 20px 0;
    &::after {
      // display: none;
    }
  }
  `
const PhaseCardQuarters = styled.div`
    position: relative;
    background-color:#C8EE5B;
    padding: 0px;
    border-radius: 5px;
    text-align: center;
    font-size: 1.2rem;
    min-height: 200px;
    
  border:2px solid #000;
    margin: 10px;
    text-align:left;
  // width:430px;
    // Add the line connecting to the next card
  
  `;

const WrapModelQuarters = styled.div`
  background-color:#C8EE5B;
  padding:0px 30px;
  border-radius:5px;
  border:2px solid #000;
  margin: 10px;
  // width:430px;
  position:relative;
   &::after {
     content: '';
    position: absolute;
    right: -83px;
    bottom: 50%;
    width: 82px;
    height: 2px;
    background-color: black;
    z-index: 0;
  }
  @media screen and (max-width: 900px) {
    // margin: 20px 0;
    &::after {
      // display: none;
    }
  }
`

// Main Component
const CarouselComponent = () => {
  const [isV1, setIsV1] = useState(false); // State to track whether V1 or V2 is active
  const sliderRef = React.createRef();

  const settingsV1 = {
    slidesToShow: 4, // Show earlier slides (Phase 1-4)
    swipeToSlide: true,
    arrows: false,
  };

  const settingsV2 = {
    slidesToShow: 4, // Show later slides
    swipeToSlide: true,
    arrows: false,
  };

  const toggleVersion = () => {
    setIsV1((prevState) => !prevState); // Toggle between V1 and V2
    if (sliderRef.current) {
      // Move slider to first set (V1) or second set (V2)
      isV1 ? sliderRef.current.slickGoTo(0) : sliderRef.current.slickGoTo(4);
    }
  };
  const settings = {
    className: "center",
    infinite: true,
    // centerPadding: "160px",
    slidesToShow: 3.1,
    swipeToSlide: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1374,
        settings: {
          slidesToShow: 2.7,
          slidesToScroll: 1,

        }
      },
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,

        }
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },

    ]
  };

  return (
    <div>
      {/* Toggle Buttons for V1 and V2 */}
      {/* <div style={{ textAlign: 'center', margin: '20px 0' }}>
        <img style={{ maxWidth: "200px" }} src={V1v2} />
      </div> */}
      <div style={{ display:"flex", justifyContent:"center", textAlign: 'center', margin: '20px 0' }}>
      <ButtonContainer data-aos="zoom-in"  data-aos-duration="400" data-aos-easing="ease-in-out" data-aos-delay="400" data-aos-offset="0"onClick={toggleVersion}>
      {/* Sliding green part */}
      <Sliderbutton isV1={isV1} />
      
      {/* V1 and V2 text */}
      <ButtonText className='textStroke' isActive={isV1}>V1</ButtonText>
      <ButtonText className='textStroke' isActive={!isV1}>V2</ButtonText>
    </ButtonContainer>
      </div>
      {/* Connected Carousel */}
      <CarouselWrapper>
        <Slider {...settings} ref={sliderRef} {...(isV1 ? settingsV1 : settingsV2)}>
          <div  data-aos="fade-down"
     data-aos-easing="linear"
     data-aos-duration="1000">
            <WrapModelQuarters>
              <h3>Q3 2024</h3>
            </WrapModelQuarters>
            <PhaseCardQuarters>
              <ul>
                <li>X and Telegram going live</li>
                <li>Website v1 going live</li>
                <li>Community building</li>
                <li>Community giveaways</li>
                <li>Successful contract deployment</li>
                <li>KOL push in 20+ differentsocial media platforms</li>
                <li>More prelaunch marketing</li>
                <li>First glimpse of the PePay Dashboard</li>
                <li>First CEX listing</li>

              </ul>
            </PhaseCardQuarters>
          </div>
          <div data-aos="fade-up"
     data-aos-duration="1000">
            <WrapModelQuarters>
              <h3>Q4 2024</h3>
            </WrapModelQuarters>
            <PhaseCardQuarters>
              <ul>
                <li> Start of our own app development </li>
                <li> PePay's staking pool going live </li>
                <li> Social media challenge </li>
                <li> Kickstart of PePay's own merch </li>
                <li> PePay Ambassador Program </li>
                <li> Second CEX listing </li>
                <li> Development of PePay's own series season 2 </li>
                <li> Launch of PePay's own series season 2 </li>
                <li> Major marketing push withbillboards</li>
              </ul>
            </PhaseCardQuarters>
          </div>
          <div  data-aos="fade-down"
     data-aos-easing="linear"
     data-aos-duration="1000">
            <WrapModelQuarters>
              <h3>Q1 2025</h3>
            </WrapModelQuarters>
            <PhaseCardQuarters>
              <ul>

                <li> Pepay's own app going live for Android & iOS</li>
                <li> PePay's own customized game going live for Android</li>
                <li> First exclusive crypto event</li>
                <li> Start of our press release</li>
                <li> Start of PePay's NFT marketplace developement</li>
                <li> Tier 1 CEX listings</li>
                <li> More major marketing pushes</li>
              </ul>

            </PhaseCardQuarters>
          </div>
          <div data-aos="fade-up"
     data-aos-duration="1000">
            <WrapModelQuarters>
              <h3>Q2 2025</h3>
            </WrapModelQuarters>
            <PhaseCardQuarters>
              <ul>
                <li> PePay Metaverse Integration</li>
                <li> Launch of PePay's NFT  marketplace development</li>
                <li> Partnership with Global Retail Chains</li>
                <li> PePay’s Global Crypto Tour(Singapore, Lissabon, Tokyo, Capetown)</li>
                <li> PePay Philanthropy Initiatives</li>
                <li> Collaborations with different companies</li>
                <li> PePay's exclusive holder meetup in Dubai</li>
              </ul>
            </PhaseCardQuarters>
          </div>
          <div>
            <WrapModel>
              <h3>Phase 1</h3>
            </WrapModel>
            <PhaseCard>
              <ul>

                <li> X and Telegram going live</li>
                <li> Website v1 going live</li>
                <li> Community building</li>
                <li> Community giveaways</li>
                <li> Successful contract deployment</li>
                <li> KOL push in 20+ different social media platforms</li>
                <li> More prelaunch marketing</li>
                <li> First glimpse of the PePay Dashboard </li>
              </ul>

            </PhaseCard>
          </div>
          <div>
            <WrapModel>
              <h3>Phase 2</h3>
            </WrapModel>
            <PhaseCard>
              <ul>
                <li> Succesful launch</li>
                <li> Coingecko and Coinmarketcap listing</li>
                <li> Partnerships</li>
                <li> Dexscreener ads</li>
                <li> ETH trending</li>
                <li> More liquidity being added 1000+ holders</li>
              </ul>



            </PhaseCard>
          </div>
          <div>
            <WrapModel>
              <h3>Phase 3</h3>
            </WrapModel>
            <PhaseCard>
              <ul>
                <li> Onboarding T1 KOL's</li>
                <li> Effective guerilla marketing (we'll be everywhere)</li>
                <li> PePay Dashboard going live!</li>
                <li> Multiple bot trendings</li>
                <li> Dextools trending</li>
                <li> More dexscreener ads</li>
                <li> First CEX listing</li>
                <li> More liquidity being added  4000+ holders</li>
              </ul>
            </PhaseCard>
          </div>
          <div>
            <WrapModel>
              <h3>Phase 4</h3>
            </WrapModel>
            <PhaseCard>
              <ul>

                <li> First card design reveal!</li>
                <li> Trending everywhere</li>
                <li> T1 CEX listing</li>
                <li> More partnerships</li>
                <li> More liquidity being added</li>
                <li> Successful integration of payments with $PEPAY</li>
                <li> 10,000+ holders</li>
              </ul>

            </PhaseCard>
          </div>
        </Slider>
      </CarouselWrapper>
    </div>
  );
};

export default CarouselComponent;
