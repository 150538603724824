import emotionStyled from '@emotion/styled'
import React, { useState } from 'react'
import { Box, Container, Grid } from '@mui/material'
import { Image } from '../../Styles/style'
import CustomButton from '../../Components/CustomButton'
import Paragraph from '../../Components/Paragraph'
import { contractAddress, dexScreener, dexTools, ethscan, supply, tax, tg, uniSwap, x } from '../../../links'
import styled from '@emotion/styled'
import heroBg from "../../../assets/tokenomicsimg.png"
// import telegram from "../../../assets/TG.png"
import FileCopyIcon from '@mui/icons-material/FileCopy';
// import twitter from "../../../assets/X.png"
import telegram from "../../../assets/gdexscreener.png"
import uni from "../../../assets/guniswap.png"
import dex from "../../../assets/gdextool.png"
import scan from "../../../assets/gethscan.png"
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import the AOS styles
// Initialize AOS
AOS.init({
    duration: 1200, // Duration of animations (optional)
    once: true, // Whether animation should happen only once - while scrolling down (optional)
    mirror: false, // Whether elements should animate out while scrolling past them (optional)
});
const SectionWraper = emotionStyled(Box)`
 background-image:url(${heroBg});
    background-size:cover;
    background-position: center;
    background-repeat:no-repeat;
    background-color:#39933C;
    min-height:auto;
    width:100%;
    display:flex;
    padding:0px 0 30px;
    justify-content:center;
    align-items:center;
    position:relative;
    // border-bottom:50px solid transparent;
    // border-image: linear-gradient(to top,#FF4F01, transparent) 30;
`
const AboutImage = styled.img`
width: 100%;
height:auto;
max-width:600px;
`
const Icons = styled.img`
width: 100%;
height:80px;
max-width:250px;

transition: all 0.2s ease;
// box-shadow: -4px 6px 0px 2px black;
:active{
    
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
transform: translateY(2px);

}
@media (max-width: 499px) {
    height:65px;
    max-width:200px;
        }
@media (max-width: 399px) {
    height:60px;
    max-width:200px;
        }
@media (max-width: 359px) {
    height:50px;
    max-width:200px;
        }
`
const ContainerBox = styled.div`
  padding: 30px 0 12px;
  display: flex;
  gap: 10px;
  justify-content: start;
  align-items: start;
  flex-wrap: wrap; /* Allow items to wrap to the next line */

  @media (max-width: 599px) {
    justify-content: center; /* Center the items for mobile */
    
    a {
      width: calc(50% - 5px); /* Each item takes up half of the row */
    }
  }
`;

const AboutSection = () => {
    const [isCopied, setIsCopied] = useState(false)
    const handleCopy = async () => {
        setIsCopied(true)
        await navigator.clipboard.writeText(contractAddress)
        setTimeout(() => {
            setIsCopied(false)
        }, 3000);
    }

    return (
        <SectionWraper id="Tokenomics">
            <Container maxWidth="xl" >
                <Grid container sx={{ display: "flex", justifyContent: "center", alignItems: { xs: "start", md: "center" } }}>

                    <Grid container item xs={12} md={8} sx={{ textAlignLast: "center", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", padding: "50px 0px", }}>
                        <Paragraph color="black" data-aos="zoom-in" data-aos-duration="900" data-aos-easing="ease-in-out" data-aos-delay="1000" data-aos-offset="0"
                         textAlignLast="center" fontFamily="KB" textALign="center" fontSize={{ xs: "50px", md: "90px" }}>
                            Tokenomics
                        </Paragraph>

                        <Paragraph data-aos="fade-up" data-aos-duration="900" data-aos-easing="ease-in-out" data-aos-delay="1000" data-aos-offset="0" textAlign="left" color="black" fontFamily="kM" mb={{ md: "10px" }} mt={{ xs: "0", md: "0px" }} fontSize={{ xs: "20px", sm: "40px" }} >
                            Token Supply:        {supply}
                        </Paragraph>

                        <Paragraph data-aos="fade-up" data-aos-duration="900" data-aos-easing="ease-in-out" data-aos-delay="1000" data-aos-offset="0" textAlign="left" color="black" fontFamily="kM" mb={{ md: "10px" }} mt={{ xs: "0", md: "0px" }} fontSize={{ xs: "20px", sm: "40px" }} >
                            Name: Pepay | Symbol: $PEPAY
                        </Paragraph>
                        <Paragraph data-aos="fade-up" data-aos-duration="900" data-aos-easing="ease-in-out" data-aos-delay="1000" data-aos-offset="0" textAlign="left" color="black" fontFamily="kM" mb={{ md: "10px" }} mt={{ xs: "0", md: "0px" }} fontSize={{ xs: "20px", sm: "40px" }} >
                            Zero Percent Taxes, No Bullshit. It’s That Simple.
                        </Paragraph>


                        <Box data-aos="zoom-in-up" data-aos-duration="900" data-aos-easing="ease-in-out" data-aos-delay="1000" data-aos-offset="0" style={{ boxShadow: "0px 6px 0px 0px black" }} border='2px solid black' borderRadius="14px" width="100%" bgcolor="#40B74B" padding="5px 10px" display="flex" alignItems={{ xs: "center", md: "center" }} flexDirection={{ xs: "row", md: "row" }} justifyContent="space-between">
                            <Paragraph color="black" fontFamily="kb" fontSize={{ xs: "20px", sm: "40px" }}>
                                Ca:
                            </Paragraph>
                            <Paragraph color="black" fontFamily="km"  fontSize={{ xs: "20px", sm: "28px" }} sx={{ overflowWrap: 'anywhere', display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }}>
                                {
                                    isCopied ? "Copied" : contractAddress
                                }<FileCopyIcon onClick={handleCopy} sx={{ color: "#000", fontSize: "32px", cursor: "pointer" }} />
                            </Paragraph>
                        </Box>

                        <ContainerBox>
      <a data-aos="zoom-in" data-aos-duration="900" data-aos-easing="ease-in-out" data-aos-delay="1000" data-aos-offset="0" href={dexScreener} target="blank">
        <Icons src={telegram} />
      </a>

      <a data-aos="zoom-in" data-aos-duration="1100" data-aos-easing="ease-in-out" data-aos-delay="1100" data-aos-offset="0"href={dexTools} target="blank">
        <Icons src={dex} />
      </a>

      <a data-aos="zoom-in" data-aos-duration="1200" data-aos-easing="ease-in-out" data-aos-delay="1200" data-aos-offset="0" href={uniSwap} target="blank">
        <Icons src={uni} />
      </a>

      <a data-aos="zoom-in" data-aos-duration="1300" data-aos-easing="ease-in-out" data-aos-delay="1300" data-aos-offset="0" href={ethscan} target="blank">
        <Icons src={scan} />
      </a>
    </ContainerBox>
                    </Grid>
                </Grid>
            </Container>
        </SectionWraper >
    )
}

export default AboutSection