import styled from "@emotion/styled";
import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import zIndex from "@mui/material/styles/zIndex";
import Paragraph from "../Paragraph";
import emotionStyled from "@emotion/styled";
import { tg } from "../../../links";
import heroBg from "../../../assets/ecobg.png"
import logo from "../../../assets/logo.png"
import X from "../../../assets/X.png"
import logoZi from "../../../assets/logozi.png"
import ecosideimg from "../../../assets/ecosideimg.png"
import stakeimg from "../../../assets/zorint.png"
import pepayapp from "../../../assets/birdt.png"
import pepaygame from "../../../assets/soet.png"
import nft from "../../../assets/dannyt.png"



import AOS from 'aos';
import 'aos/dist/aos.css'; // Import the AOS styles
// Initialize AOS
AOS.init({
    duration: 1200, // Duration of animations (optional)
    once: true, // Whether animation should happen only once - while scrolling down (optional)
    mirror: false, // Whether elements should animate out while scrolling past them (optional)
});
const SectionWrapper = emotionStyled(Box)`
    // background-size: cover;
    // background-repeat: no-repeat;
    // background-position-x: center;
    background-color: #39933C;
    padding: 80px 0 150PX;
    border-top:1px solid #000;
    border-bottom:1px solid #000;
    
`
const LogoImg = emotionStyled.img`
width:100%;
height:auto;
max-width:200px;
`
const Ximg = emotionStyled.img`
width:100%;
height:auto;
max-width:30px;
`
const PepEcoCard = emotionStyled.img`
width:100%;
height:auto;
max-width:1130px;
`
const Stakeimg = emotionStyled.img`
width:100%;
height:auto;
max-width:365px;
`
const ImgGroup = emotionStyled.div`
// display:flex;
justfy-content:center;
gap:20px;
text-align:center;
`
const CustomGrid = emotionStyled(Grid)`
  display: flex;
  width: 100%;
  align-items: ${(props) => (props.ai ? props.ai : "center")};
  justify-content: ${(props) => (props.jc ? props.jc : "center")};
  text-align: ${(props) => (props.tl ? props.tl : "center")};
  padding: ${(props) => (props.p ? props.p : "0px")};
  margin: ${(props) => (props.m ? props.m : "0")};
  gap: ${(props) => (props.g ? props.g : "0")};
  
`;
const GridSWrap = emotionStyled(Grid)`
  background-image:url(${heroBg});
    background-size:cover;
    background-position: center;
    background-repeat:no-repeat;  
    justify-content:left;
    overflow:hidden;
    border-radius:20px;
  border:2px solid #000;
  padding:30px;
  
`;
const BUTTDDF = styled.button`
border-width: 0px;
background: #C8EE5B;
cursor:pointer;
font-family: kb;
    // width: calc(100% - 40px);
    color: #000;
    margin: 0px auto;
    padding: 10px 25px;
    text-align: center;
    border-radius:10px;
    BORDER:2PX SOLID BLACK;
font-size:20px;
    transition: all 0.2s ease;
    box-shadow: 0px 6px 0px 0px #000;
    // border: 2px solid black;
    :active{
        
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
        transform: translateY(2px);
        
    }
        `
const CustomDiv = styled.div`
    background-color:#C8EE5B;
    width:100%;
    max-width:700px;
    border-radius:10px;
    `



const EcoImg = emotionStyled.img`
width:100%;
height:auto;
border-radius:0 0 9px 9px;
`;
const EcoWrap = emotionStyled.div`
// background-color:#8D8D8D;
padding:0px 0 0px;
position: relative;
`;
const PoolContainer = emotionStyled.div`
background-color:#C8EE5B;
padding:0px 0 10px; 
border-radius: 14px; /* For rounded corners if needed */
border-bottom:2px solid black;
border-left:1px solid black;
border-right:1px solid black;

`;

const OverlayText = emotionStyled.div`
position: absolute;
bottom: 0;
width: 100%;
background: linear-gradient(0, black, transparent);
padding: 113px 0;
`;

const Typographys = emotionStyled(Typography)`
position:Absolute;
left: 0;
place-self: center;
right: 0;
bottom: 10px;

`;

const HowToBuy = () => {
    return (
        <SectionWrapper id="Team">
            <Container maxWidth="xxl" id="Howtobuy">
                <Container maxWidth="xl" >
                    {/* <Grid container sx={{ justifyContent: "CENTER", display: "flex", gap: { xs: "10px", lg: "20px" } }} > */}
                    <CustomGrid data-aos="zoom-in" item xs={12}><Typography className="textStroke" variant='h3' fontFamily="kb" textAlign='center' color="#fff" textTransform="camelcase" fontSize={{ xs: "50px", sm: "80px" }} >
                        TEAM</Typography></CustomGrid>

                    <Grid container gap={2} justifyContent="center">
                        <Grid item xs={12} sm={6} md={5.8} lg={2.7} style={{ display: "flex", padding: "20px 0" }}>
                            <PoolContainer data-aos="fade-up"
                                data-aos-anchor-placement="center-bottom"
                                data-aos-duration="900" data-aos-easing="ease-in-out" data-aos-delay="500" data-aos-offset="0"
                            ><EcoImg src={stakeimg} />

                                <Typography style={{ margin: "0", textAlign: "left", padding: "22px 10px 0px 20px", textWrap: "wrap" }} fontFamily="kb" textAlign="center" whiteSpace="nowrap" color="#000" fontSize={{ xs: "26px", sm: "35px" }}  >
                                    Zorin Zyno
                                </Typography>
                                <Typography style={{ margin: "0", textAlign: "left", padding: "0px 10px 10px 20px", textWrap: "wrap" }} fontFamily="km" textAlign="center" whiteSpace="nowrap" color="#000" fontSize={{ xs: "16px", sm: "22px" }}  >
                                    Core Team Member/<br/>
                                    Lead Dev
                                </Typography>
                            </PoolContainer>
                        </Grid>
                       
                        <Grid item xs={12} sm={6} md={5.8} lg={2.7} style={{ display: "flex", padding: "20px 0" }}>
                            <PoolContainer data-aos="fade-up"
                                data-aos-anchor-placement="center-bottom"
                                data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-delay="600" data-aos-offset="0"
                            ><EcoImg src={pepayapp} />

                                <Typography style={{ margin: "0", textAlign: "left", padding: "22px 10px 0px 20px", textWrap: "wrap" }} fontFamily="kb" textAlign="center" whiteSpace="nowrap" color="#000" fontSize={{ xs: "26px", sm: "35px" }}  >
                                Bird                                </Typography>
                                <Typography style={{ margin: "0", textAlign: "left", padding: "0px 10px 0px 20px", textWrap: "wrap" }} fontFamily="km" textAlign="center" whiteSpace="nowrap" color="#000" fontSize={{ xs: "16px", sm: "22px" }}  >
                                    Core Team Member
                                </Typography>
                            </PoolContainer>
                        </Grid>
                       
                        <Grid item xs={12} sm={6} md={5.8} lg={2.7} style={{ display: "flex", padding: "20px 0" }}>
                            <PoolContainer data-aos="fade-up"
                                data-aos-anchor-placement="center-bottom"
                                data-aos-duration="1100" data-aos-easing="ease-in-out" data-aos-delay="700" data-aos-offset="0"
                            ><EcoImg src={pepaygame} />

                                <Typography style={{ margin: "0", textAlign: "left", padding: "22px 10px 0px 20px", textWrap: "wrap" }} fontFamily="kb" textAlign="center" whiteSpace="nowrap" color="#000" fontSize={{ xs: "26px", sm: "35px" }}  >
                                Soef
                                </Typography>
                                <Typography style={{ margin: "0", textAlign: "left", padding: "0px 10px 0px 20px", textWrap: "wrap" }} fontFamily="km" textAlign="center" whiteSpace="nowrap" color="#000" fontSize={{ xs: "16px", sm: "22px" }}  >
                                    Core Team Member
                                </Typography>
                            </PoolContainer>
                        </Grid>
                       
                        <Grid item xs={12} sm={6} md={5.8} lg={2.7} style={{ display: "flex", padding: "20px 0" }}>
                            <PoolContainer data-aos="fade-up"
                                data-aos-anchor-placement="center-bottom"
                                data-aos-duration="1200" data-aos-easing="ease-in-out" data-aos-delay="800" data-aos-offset="0"
                            ><EcoImg src={nft} />

                                <Typography style={{ margin: "0", textAlign: "left", padding: "22px 10px 0px 20px", textWrap: "wrap" }} fontFamily="kb" textAlign="center" whiteSpace="nowrap" color="#000" fontSize={{ xs: "26px", sm: "35px" }}  >
                                Danny                                </Typography>
                                <Typography style={{ margin: "0", textAlign: "left", padding: "0px 10px 0px 20px", textWrap: "wrap" }} fontFamily="km" textAlign="center" whiteSpace="nowrap" color="#000" fontSize={{ xs: "16px", sm: "22px" }}  >
                                    Core Team Member
                                </Typography>
                            </PoolContainer>
                        </Grid>
                       

                    </Grid>
                   

                    {/* </Grid> */}
                </Container>
            </Container>
        </SectionWrapper>
    );
};

export default HowToBuy;