import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import logo from "../../../../assets/logo.png";
import twitter from "../../../../assets/twitter.png";
import telegram from "../../../../assets/telegram.png";
import { DesktopMainMenu, MaterialUISwitch, MenuLink } from "../styles";
import { toggleTheme } from '../../../../Redux/switchTheme'
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";
import Paragraph from "../../../Sections/Paragraph";

const DesktopMenu = (props) => {
  const currentTheme = useSelector( (state) => state.LDTheme.value )
  const dispatch = useDispatch();
 
  return (
    <DesktopMainMenu maxWidth="xl">
      <a href="/" style={{padding:"6px 00 0"}}p="10px 0 0">
        <img src={logo} width="210" height='65' alt="Logo" />
      </a>
     
      <div>
        {props.menuList.map((value, i) => <MenuLink key={i} href={value.link} target={value.target} className={value.customClass}>{value.title}</MenuLink> )}
 
      </div>
    
    </DesktopMainMenu>
    
  );
};

export default DesktopMenu;
