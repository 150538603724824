// src/components/PhasesSection.js

import React from 'react';
import { Grid, Card, CardContent, Typography, Box, Container } from '@mui/material';
import { styled } from '@mui/system';
import Paragraph from '../../Components/Paragraph';
import car from "../../../assets/car.png"
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import the AOS styles
import emotionStyled from '@emotion/styled';
import Carousel from '../S5_1_Roadmap';
// Initialize AOS
AOS.init({
  duration: 1200, // Duration of animations (optional)
  once: true, // Whether animation should happen only once - while scrolling down (optional)
  mirror: false, // Whether elements should animate out while scrolling past them (optional)
});
const SectionWraper = emotionStyled(Box)`
    background-size:cover;
    background-repeat:no-repeat;
    // background-color:#39933C;
    // min-height:100VH;
    width:100%;
    justify-content:center;
    align-items:center;
`
const Rap = styled('div')({
  backgroundImage: 'url(${heroBg})',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: "no-repeat",
  padding: '40px 20px',
  // minHeight: '100vh',
  // display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const PhaseCard = styled(Card)({
  backgroundColor: '#C8EE5B', // Card background color
  margin: '20px',
  borderRadius: '16px',
  boxShadow: '0 3px 5px rgba(0, 0, 0, 0.3)',
  maxWidth: '450px',
  width: "100%"
});

const PhaseTitle = styled(Typography)({
  color: '#1C5D1C', // Title color
  fontWeight: 'bold',
  marginBottom: '5px',
  fontFamily: "gb",
  fontSize: "40px"
});
const Gridx = styled(Grid)({
  display: "none",
  '@media screen and (max-width: 899px)': {
    display: 'flex',
  },
});
const Gridy = styled(Grid)({
  display: "flex",
  '@media screen and (max-width: 899px)': {
    display: 'none',
  },
});

const PhaseContent = styled(Typography)({
  color: '#166815',
  // lineHeight: '1.6',
  fontSize: '23px'

});
const LineImage = styled('img')({
  margin: '0 20px',
  height: '100%',
  width: '100%',
  maxWidth: '40px',
  '@media screen and (max-width: 899px)': {
    display: 'none',
  },
});



const PhasesSection = () => {
  return (
    <div style={{textAlign:"center", backgroundColor:"#40B74B"}}>
  <img style={{width:"100%" , height:"auto"}} src={car}/>
    <SectionWraper id="Roadmap">
      <Container>
        <Paragraph className="textStroke" data-aos="zoom-in" fontFamily="kb" fontSize={{ xs: "50px", sm: "90px" }}>
          Roadmap
        </Paragraph>

        <Typography style={{ margin: "0", textAlign: "CENTER", padding: "40px 0", textWrap: "wrap" }} data-aos="fade-up" fontFamily="kr" textAlign="CENTER" whiteSpace="nowrap" color="#fff" fontSize={{ xs: "20px", sm: "30px" }}  >
          Pepay Coin features two distinct roadmaps to guide its development: V1 and V2. Each roadmap represents a key phase in the evolution of our project. V1 outlines the foundational steps we've taken to establish Pepay Coin, while V2 introduces future advancements and features designed to elevate the project to new heights. Explore both versions to see how we're driving progress and expanding our Merchandise!

        </Typography>
      </Container>
    </SectionWraper>
      <Carousel />
    </div>
  );
};

export default PhasesSection;
