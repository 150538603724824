import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
const BUTTDDF = styled.button`
border-width: 0px;
background: #C8EE5B;
cursor:pointer;
font-family: kb;
    // width: calc(100% - 40px);
    width: 90px;
    color: #000;
    margin: 0px auto;
    padding: 5px 10px;
    text-align: center;
    border-radius:10px;
    BORDER:2PX SOLID BLACK;
font-size:16px;
    transition: all 0.2s ease;
    box-shadow: 0px 6px 0px 0px #000;
    // border: 2px solid black;
    :active{
        
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
        transform: translateY(2px);
        
    }
        `
const VideoPlayer = ({ videoSrc }) => {
  const videoRef = useRef(null);
  const [isMuted, setIsMuted] = useState(true); // Video will be muted by default

  // Function to toggle mute
  const toggleMute = () => {
    const videoElement = videoRef.current;
    videoElement.muted = !isMuted;
    setIsMuted(!isMuted);
  };

  // Function to play the video when it enters the viewport
  const handlePlayVideo = (entries) => {
    const entry = entries[0];
    const videoElement = videoRef.current;

    if (entry.isIntersecting) {
      videoElement.play();
    } else {
      videoElement.pause();
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handlePlayVideo, {
      threshold: 0.5, // Video should be at least 50% visible
    });

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    // Clean up observer when component is unmounted
    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);
  const Videobg = styled.video`
  border-radius:20px;
  border:2px solid black;
  
  `
  return (
    <div style={{position:"relative" ,width:"100%", height:"auto"}}>
      <video
      style={{borderRadius:"20px",border:"2px solid black" }}
        ref={videoRef}
        autoPlay // Autoplay will be handled via IntersectionObserver
        muted={isMuted}
        controls={false} // Remove default controls, we'll handle mute and play
        width="100%" // Adjust this to fit your layout
        loop
      >
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <BUTTDDF style={{position:"absolute" ,top:"0", left:"0"}} onClick={toggleMute}>
        {isMuted ? 'Unmute' : 'Mute'}
      </BUTTDDF>
    </div>
  );
};

export default VideoPlayer;
